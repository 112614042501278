* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --gray-color: #747474;
  --light-gray: #cfcfcf;
  --primary-color: #5ae6cb;
  --light-primary-color: #e9faf7;
  --lighter-gray: #f0f2f5;
}
.cursor-pointer {
  cursor: pointer !important;
}
.bg-light-primary {
  background-color: var(--light-primary-color);
}
.bg-primary {
  background: var(--primary-color) !important;
}
a {
  text-decoration: none;
  color: black;
}
.fs-14 {
  font-size: 13px !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.border-bottom-gray {
  border-bottom: 1px solid var(--gray-color);
}
.outline-0 {
  box-shadow: none;
  outline: none;
}
.bg-light-gray {
  background-color: var(--lighter-gray);
}
.bg-none {
  background: none !important;
}
input {
  box-shadow: none !important;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Remove arrows from input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
