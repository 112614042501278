/* Navbar  */

.nav {
  border-bottom: 2px solid var(--light-gray);
}
.logo {
  width: 200px;
}
.logo > img {
  width: 100%;
}
.icon {
  color: var(--gray-color);
}
.search-box {
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 7px 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 350px;
}
.search-box > input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 15px;
}
.search-box > input::placeholder {
  color: var(--gray-color);
}
.divider {
  width: 2px;
  height: 20px;
  background-color: var(--light-gray);
}
.search-results {
  max-height: 400px;
  width: 100% !important;
  background-color: #555;
  overflow-y: scroll;
}
.searched-post {
  max-height: 110px;
  overflow: hidden;
  border-bottom: 1px solid var(--light-gray);
}
.searched-user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.searched-post-img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-left: 10px;
  object-fit: cover;
}
.searched-post-text {
  width: 100%; /* set width if needed */
  height: 100%; /* set height based on number of lines */
  font-size: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to show */
  text-overflow: ellipsis;
}
.profile > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.btn1 {
  background-color: var(--light-primary-color);
  border: none;
  outline: none;
  padding: 5px 25px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  transition: 0.5s background-color;
  color: black;
  text-decoration: none;
}
.btn1:hover {
  background-color: var(--primary-color);
}
.btn2 {
  background-color: var(--primary-color);
  border: none;
  outline: none;
  padding: 5px 25px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  transition: 0.5s background-color;
  color: white;
  text-decoration: none;
}
.notifications {
  width: 400px !important;
  max-height: 300px;
  overflow-y: scroll;
}
.notification-box {
  transition: all 0.2s linear;
  /* padding: 10px; */
}
.notification-box:hover {
  background-color: var(--lighter-gray);
}
.notification-left {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--light-primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.red-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 2px;
  right: 0;
}
/* chat  */
.messages-container {
  height: 85%;
}
.chat-options {
  background-color: var(--lighter-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
}
.chat-search {
  background-color: var(--lighter-gray);
  border: none !important;
}
.chat-search > input::placeholder {
  color: var(--gray-color);
}
.chat_inbox {
  font-size: 14px;
}
.chat_inbox:focus {
  border: 0 !important;
  outline: none !important;
}
.chat_inbox:active {
  border: 0 !important;
  outline: none !important;
  /* background-color: rgb(127, 236, 255); */
}
.chat_inbox:hover {
  background-color: var(--light-primary-color);
  color: var(--primary-color);
}
.chat-head {
  box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.05);
  /* border: 1px solid var(--light-gray); */
}
.message_box {
  width: fit-content;
}
.online-sign {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #83bf6e;
  border-radius: 50%;
  margin-right: 5px;
  border: 2px solid white;
}
.offline-sign {
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #ccc;
  border-radius: 50%;
  margin-right: 5px;
  border: 2px solid white;
}
.chats-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.message-box {
  background: #f0f2f5;
  border-radius: 12px;
  padding: 5px 0;
}
.message-box > input {
  border: none;
  outline: none;
  width: 100%;
}
.message-box > input:focus {
  outline: none;
  border: none;
}
.message-box > input:active {
  outline: none;
  border: none;
}

/* signup  */
.signup_container {
  /* min-height: 100vh; */
  width: 100%;
  height: auto;
}
.signup-box {
  width: 70%;
}
.signup_form {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
  border-right: 1px solid var(--light-gray);
}
.signup_form.border-all {
  border: 1px solid var(--light-gray);
}
.s_logo {
  width: 150px;
}
.s_logo > img {
  width: 100%;
}
.underline {
  height: 6px;
  width: 40px;
  margin: 0 auto;
}
.s_input {
  border: 1px solid var(--lighter-gray) !important;
  width: 100%;
}
.s_input > input::placeholder {
  font-size: 14px;
}
.checkbox {
  color: var(--gray-color);
  gap: 8px;
}
.checkbox > label {
  font-size: 13px;
}
.forgot {
  font-size: 13px;
  color: var(--gray-color);
  text-decoration: underline;
}
.checkbox > input {
  accent-color: var(--gray-color);
}
.continue_line {
  width: 32%;
  background-color: var(--light-gray);
  height: 1px;
}
.continue {
  color: var(--gray-color);
  font-size: 12px;
}

/* setting  */

.sidebar-container .tabs {
  padding: 12px 0;
  position: relative;
}

.sidebar-container .active-link .small-text {
  color: #5ae6cb;
  font-weight: bold;
}

.sidebar-container .active-link {
  color: #5ae6cb !important;
}

.sidebar-container .active-link::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: #5ae6cb;
  border-radius: 2px;
}
.tabs:hover p {
  color: var(--primary-color);
}
.tabs:hover .sidebar_icon {
  color: var(--primary-color);
}
.setting-layout {
  min-height: 100vh;
  width: 100%;
  height: auto;
}
.s_sidebar_box {
  border-bottom: 1px solid var(--light-gray);
}
.profile-settings h3 {
  border-bottom: 1px solid var(--gray-color);
  padding-bottom: 10px;
}
.profile-img {
  width: 7rem;
  height: 7rem;
}
.profile-img > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.cancel {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px 25px;
  border-radius: 5px;
  font-weight: semibold;
  font-size: 15px;
  transition: 0.5s background-color;
  color: var(--gray-color);
  text-decoration: none;
  border: 1px solid var(--light-gray);
}
.profile-edit {
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 7px 20px;
}
.profile-edi::placeholder {
  color: var(--gray-color);
  font-size: 14px;
}
.profile-edit:focus {
  outline: none;
}
.select-notification::placeholder {
  font-size: 13px;
  color: var(--gray-color);
  font-weight: 600;
}

input[type="radio"] {
  accent-color: var(--primary-color) !important;
}
.search-box label {
  color: var(--gray-color);
  font-size: 15px;
}
.img_icon {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}
.paypall {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordion-question {
  margin: 0;
  font-size: 16px;
}

.accordion-icon {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.accordion-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
}

.accordion-content p {
  margin: 0;
  padding: 8px 0;
  padding-bottom: 10px;
  color: var(--gray-color);
}

/* NotificationToggle*/
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 18px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #5ae6cb;
}

input:checked + .slider:before {
  transform: translateX(16px);
}
.custome_table > thead > tr > th {
  border-bottom: 1px solid var(--gray-color) !important;
  color: var(--gray-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.custome_table > tbody > tr > td {
  border-bottom: none !important;
  font-size: 15px !important;
}

/* Footer  */
footer {
  background: black;
}
.footer-link {
  color: var(--light-gray);
  text-decoration: none;
  font-size: 14px;
  font-size: 600;
}
.horizontal-divider {
  width: 100%;
  height: 2px;
  background-color: var(--light-gray);
}

/* Sidebar  */
.sidebar-container {
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  padding: 10px 15px;
}
.primary-text {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.small-text {
  margin: 0;
  font-size: 13px;
  color: var(--gray-color);
  font-weight: 600;
}
.smaller-text {
  margin: 0;
  font-size: 10px;
  color: var(--gray-color);
  font-weight: 400;
}
.small-text > span {
  font-size: 9px;
  color: rgb(204, 204, 204);
  margin-left: 3px;
}
.profile-box > img {
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.primary-icon {
  color: var(--primary-color);
}
.primary-heading {
  font-size: 23px;
  font-weight: 600;
  margin: 0;
}

.secondary-heading {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
.img-container {
  width: 100%;
  position: relative;
}
.img-container > img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 3px;
}
.floating-text {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  border-radius: 5px;
  text-align: center;
  padding: 5px 30px;
  color: black;
  font-weight: 600;
  font-size: 15px;
}
.topic-profile > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* Home  */
.text_muted {
  color: #747474;
  cursor: pointer;
}
.green_line {
  background-color: var(--primary-color);
  height: 26px;
  width: 12px;
  border-radius: 2px;
}
.post_input {
  resize: none;
  border: none;
  outline: none;
  font-size: 13px;
  color: var(--gray-color);
  font-weight: 500;
}

.post_input::placeholder {
  color: rgb(156, 156, 156);
  font-size: 13px;
}
.post_input:focus {
  border: none;
  outline: none;
}
.other_profile > img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--light-gray);
}
.trending-topics-container {
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.trending-topic-box {
  border-radius: 5px;
  height: 320px;
  position: relative;
  width: 230px;
  margin-right: 20px;
}
.trending-topic-box:last-child {
  margin: 0;
}

.trending-topic-box > img {
  border-radius: 5px;
  width: 230px;
  height: 100%;
  object-fit: cover;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(243, 243, 243);
  background: linear-gradient(
    180deg,
    rgba(243, 243, 243, 0) 0%,
    rgba(25, 25, 25, 0.3563550420168067) 60%,
    rgba(0, 0, 0, 0.6420693277310925) 100%
  );
  border-radius: 5px;
}
.topic-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  width: 100%;
  color: white;
}

.trending-topics-container::-webkit-scrollbar {
  display: none;
}

.navigation-buttons .nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1000;
  font-size: 40px;
  transition: all 0.5s ease-in-out;
}
.nav-btn.left-btn {
  left: -50px;
}
.nav-btn.right-btn {
  right: -50px;
}

.trending-topics:hover .nav-btn.left-btn {
  left: 10px;
}
.trending-topics:hover .nav-btn.right-btn {
  right: 10px;
}
.post-pic {
  width: 100%;
  height: 350px;
  border-radius: 5px;
  overflow: hidden;
}
.post-pic > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-actions {
  border-top: 1px solid var(--light-gray);
  border-bottom: 1px solid var(--light-gray);
}
.primary-hover {
  transition: all 0.2s ease-in-out;
}
.primary-hover:hover {
  color: var(--primary-color);
}
.comments {
  max-height: 300px;
  overflow-y: scroll;
}
.comment-box {
  margin-top: 15px;
}
.comment-img > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}
.comment {
  background: var(--lighter-gray);
  border-radius: 5px;
  padding: 5px 10px;
}
.lighter-font {
  font-weight: 400;
}

/* Add your transition styles */
.comments-section-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.comments-section-enter-active {
  max-height: 100vh; /* Adjust based on your content */
  opacity: 1;
}

.comments-section-exit {
  max-height: 100vh; /* Adjust based on your content */
  opacity: 1;
  overflow: hidden;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}

.comments-section-exit-active {
  max-height: 0;
  opacity: 0;
}
.profile-pic {
  position: relative;
  border: 1px solid var(--gray-color);
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.profile-pic > img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.camera-icon {
  position: absolute;
  background: #e9e9ea;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  bottom: 0px;
  right: -5px;
}
.subheading {
  color: var(--gray-color);
  font-size: 18px;
}
.tab-btn {
  background: none;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}
.tab-btn:hover {
  color: var(--primary-color);
}
.tab-btn.active {
  background: var(--primary-color);
  color: white;
}
.btn3 {
  background: none;
  outline: none;
  border: 1px solid var(--light-gray);
  padding: 8px 20px;
  border-radius: 4px;
  color: var(--gray-color);
  font-weight: 500;
}

.btn4 {
  background: none;
  outline: none;
  border: 1px solid var(--light-gray);
  padding: 0px 10px;
  border-radius: 4px;
  color: var(--gray-color);
  font-weight: 500;
  font-size: 12px;
}
.btn5 {
  background: none;
  outline: none;
  border: 1px solid var(--primary-color);
  padding: 0px 10px;
  border-radius: 4px;
  color: var(--primary-color);
  font-weight: 500;
  font-size: 12px;
}
.profile-card {
  border-radius: 4px;
  border: 1px solid var(--light-gray);
  overflow: hidden;
}
.profile-card-img {
  width: 100%;
  height: 250px;
  position: relative;
}
.profile-card-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-card-img > .camera-icon {
  right: 10px;
  bottom: 10px;
}
.gray-color {
  color: var(--gray-color);
}
.profile-card-box {
  border: 1px solid var(--light-gray);
  padding: 5px 10px;
  border-radius: 2px;
}
.sponsor-images > img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}
.sort-dropdown p {
  font-size: 16px;
  margin-bottom: 5px;
}
.search-comments {
  width: 200px;
}
.profile-menu {
  position: absolute;
  background: white;
  padding: 10px;
  border-radius: 5px;
  top: calc(100% + 10px);
  right: 0;
  z-index: 1200;
  width: 200px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.32);
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.32);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.32);
}

.profile-menu-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.profile-menu-enter-active {
  max-height: 100vh; /* Adjust based on your content */
  opacity: 1;
}

.profile-menu-exit {
  max-height: 100vh; /* Adjust based on your content */
  opacity: 1;
  overflow: hidden;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}

.profile-menu-exit-active {
  max-height: 0;
  opacity: 0;
}
.menu-bars {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  background: var(--primary-color);
  color: white;
  border-radius: 3px;
}
.profile-tabs {
  width: 100%;
  overflow-x: scroll;
}
.qr-img {
  width: 70%;
}

/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.dropdown-items {
  left: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.15);
  padding: 10px;
}
.list-group-item {
  color: #555;
  /* border-color: gray; */
  cursor: pointer;
  transition: all 0.2s linear;
  font-weight: 500;
  font-size: 14px;
}
.list-group-item:hover {
  color: #5ae6cb;
}
.custom-shadow {
  box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 3px 10px 5px rgba(0, 0, 0, 0.03);
}
.head-post-img {
  height: 300px;
  object-fit: cover;
}

@media (max-width: 992px) {
  .search-box {
    width: auto;
  }
  .primary-text {
    font-size: 13px;
  }
  .s_welcome {
    display: none !important;
  }
  .signup_form {
    width: 100% !important;
    border-left: 1px solid var(--light-gray);
  }
}
@media (max-width: 480px) {
  .post-pic {
    height: 280px;
  }
  .primary-heading {
    font-size: 14px;
  }
  .primary-text {
    font-size: 11px;
  }
  .logo {
    width: 150px;
  }
  .small-text {
    font-size: 9px;
  }
  .footer-link {
    font-size: 11px;
  }
}
